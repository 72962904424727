import http from "../http-common";
const getAll = (scheduleDate, driverId) => {
  const params = {}
  if (driverId) {
    params.driverId = driverId;
  }
  if (scheduleDate) {
    params.scheduleDate = scheduleDate
  }
  return http.get('/routes', {
    params
  });
};

const updateRoute = (id, data) => {
	return http.put(`/routes/${id}`, data);
};

const createNewRoute = (data) => {
	return http.post('/routes', data);
};

const deleteRoute = (id) => {
	return http.delete(`/routes/${id}`);
}

export const TransRoutesService = {
  getAll,
	updateRoute,
	createNewRoute,
	deleteRoute
};
