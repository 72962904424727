import {createSelector} from "@reduxjs/toolkit";

export const transRoutesSelector = (store) => store.transRoutes;

export const selectAllRoutes = createSelector([transRoutesSelector], (trans) => trans && trans.routes);
export const selectTomorrowAllRoutes = createSelector([transRoutesSelector], (trans) => trans && trans.tomorrowRoutes);
export const selectHistoryRoutes = createSelector([transRoutesSelector], (trans) => trans && trans.historyRoutes);
export const selectInboundRoutes = createSelector([selectAllRoutes], (routes) => routes && routes.filter((route) => route.type === "inbound"));
export const selectOutboundRoutes = createSelector([selectAllRoutes], (routes) => routes && routes.filter((route) => route.type === "outbound"));
export const selectTomorrowInboundRoutes = createSelector([selectTomorrowAllRoutes], (routes) => routes && routes.filter((route) => route.type === "inbound"));
export const selectTomorrowOutboundRoutes = createSelector([selectTomorrowAllRoutes], (routes) => routes && routes.filter((route) => route.type === "outbound"));
export const selectHistoryInboundRoutes = createSelector([selectHistoryRoutes], (routes) => routes && routes.filter((route) => route.type === "inbound"));
export const selectHistoryOutboundRoutes = createSelector([selectHistoryRoutes], (routes) => routes && routes.filter((route) => route.type === "outbound"));