import React, {useEffect} from "react";
import {AuthService} from './../../services';
import { useNavigate } from "react-router-dom";
import { LEGACY_LINK} from "./../../shared/constants";

const Landing = () => {
  const navigate = useNavigate();
  const goToAdmin = () => {
    navigate('/admin');
  }

  useEffect(() => {
    if (!AuthService.canAccessLegacySystem()) {
      window.alert('You haven\'t login yet OR this user does not have access to this page. Please change an admin account to login.')
      AuthService.logout();
      navigate(`/login`);
    }
  }, [])
  
  return (
    <div className="list row">
      <div className="col-md-12 landing">
        <div className="landing-img"/>
        <div className="landing-content-title">
          <h2 className="landing-title">Worldshine Group </h2>
          <h2 className="landing-title">Online Management System</h2>
          <div className="landing-content-container">
            <div className="landing-content">
              
              <button onClick={() => goToAdmin()} className="btn btn-primary ps-4 pe-4">Access To Transportation</button>
            </div>
            <div className="landing-content">
              
              <button className="btn btn-primary btn-medical"><a href={LEGACY_LINK}>Access To Medical</a></button>
            </div>
            <div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;