import React, {useState, useEffect} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthService, CustomerService } from "../../services";

const ViewCustomer = () => {
  const navigate = useNavigate();

  const urlParams = useParams();
  const [currentCustomer, setCurrentCustomer] =  useState(undefined);
  const [currentAvatar, setCurrentAvatar] = useState(undefined);
	
  const redirectTo = () => {
		navigate(`/customers/list`)
  }

  useEffect(() => {
    if (!AuthService.canViewCustomers()) {
      window.alert('You haven\'t login yet OR this user does not have access to this page. Please change an admin account to login.')
      AuthService.logout();
      navigate(`/login`);
    }
    if (!currentCustomer) {
      CustomerService.getCustomer(urlParams.id).then((data) => {
        setCurrentCustomer(data.data);
      })
    }
  }, []);

  useEffect(() => {
    if (currentCustomer?.id ) {
      CustomerService.getAvatar(currentCustomer?.id).then((data) => {
        setCurrentAvatar(data.data);
      })
    }
  }, [currentCustomer]);
  
  return (
    <>
      <div className="list row mb-4">
        <div className="col-md-12 text-primary">
           <h5>{currentCustomer?.name} <button className="btn btn-link btn-sm" onClick={() => {redirectTo()}}>Back</button></h5> 
        </div>
      </div>
      <div className="list row mb-4">
        <div className="col-md-12 mb-4">
					{currentAvatar && <img height="100px" width="100px" src={`data:image/png;base64, ${currentAvatar}`}/>}
        </div>
        <div className="col-md-4 mb-4">
					<div>Name: {currentCustomer?.name}</div> 
        </div>
        <div className="col-md-4 mb-4">
					<div>First Name: {currentCustomer?.firstname}</div> 
        </div>
				<div className="col-md-4 mb-4">
			  	<div>Last Name: {currentCustomer?.lastname}</div> 
        </div>
        <div className="col-md-4 mb-4">
          <div>Chinese Name (中文姓名): {currentCustomer?.name_cn}</div> 
        </div>
        <div className="col-md-4 mb-4">
					<div>Birth Date: {currentCustomer?.birth_date}</div> 
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Table Id:{currentCustomer?.table_id}</div> 
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Customer Type:{currentCustomer?.type}</div> 
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Pickup Status:{currentCustomer?.pickup_status}</div> 
        </div>
        <div className="col-md-4 mb-4">
					<div>Email: {currentCustomer?.email}</div> 
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Care Provider:{currentCustomer?.care_provider}</div> 
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Emergency Contact:{currentCustomer?.emergency_contact}</div> 
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Medicare Number:{currentCustomer?.medicare_number}</div> 
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Medicaid Number:{currentCustomer?.medicaid_number}</div> 
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Pharmacy:{currentCustomer?.pharmacy}</div> 
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Pharmacy ID:{currentCustomer?.pharmacy_id}</div> 
        </div>
        <div className="col-md-4 mb-4">
					<div>Phone: {currentCustomer?.phone}</div> 
        </div>
        <div className="col-md-4 mb-4">
          <div>Mobile Phone: {currentCustomer?.mobile_phone}</div> 
        </div>
        <div className="col-md-4 mb-4">
					<div>Home Phone: {currentCustomer?.home_phone}</div>
        </div>
        <div className="col-md-4 mb-4">
					<div>Special Needs:{currentCustomer?.special_needs}</div>
        </div>
        <div className="col-md-4 mb-4">
					<div>Language: {currentCustomer?.language}</div>
        </div>
        <div className="col-md-4 mb-4">
          <div>Username: {currentCustomer?.username}</div>
        </div>
        <div className="col-md-4 mb-4">
          <div>Address 1: {currentCustomer?.address1}</div>
        </div>
        <div className="col-md-4 mb-4">
          <div>Address 2: {currentCustomer?.address2}</div>
        </div>
        <div className="col-md-4 mb-4">
          <div>Address 3: {currentCustomer?.address3}</div>
        </div>
        <div className="col-md-4 mb-4">
          <div>Apartment: {currentCustomer?.apartment}</div>
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Note: {currentCustomer?.note}</div> 
				</div>
        <div className="col-md-4 mb-4">
			  	<div>Admission Date: {currentCustomer?.admission_date}</div>
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Discharge Date: {currentCustomer?.discharge_date}</div>
        </div>
        <div className="col-md-4 mb-4">
					<div>Pin:{currentCustomer?.pin}</div>
        </div>
        <div className="col-md-4 mb-4">
					<div>Seating:{currentCustomer?.seating}</div>
        </div>
        <div className="col-md-4 mb-4">
					<div>Vehicle No: {currentCustomer?.vehicle_no}</div>
        </div>
        <div className="col-md-4 mb-4">
					<div>Caller:{currentCustomer?.caller}</div>
        </div>
        <div className="col-md-4 mb-4">
					<div>Placement:{currentCustomer?.placement}</div>
        </div>
        <div className="col-md-4 mb-4">
					<div>Groups:{currentCustomer?.groups?.join(', ')}</div>
        </div>
        <div className="col-md-4 mb-4">
					<div>Tags:{currentCustomer?.tags?.join(', ')}</div>
        </div>
        <div className="col-md-4 mb-4">
					<div>Roles:{currentCustomer?.roles?.join(', ')}</div>
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Private Note:{currentCustomer?.private_note}</div> 
        </div>
      </div>
    </>
  );
};

export default ViewCustomer;