import React, {useState} from "react";
import { useDispatch } from "react-redux";
import { transRoutesSlice } from "../../store";
import RouteCard from "./RouteCard";
import { Modal, Button } from "react-bootstrap";
import { PERSONAL_ROUTE_STATUS, PICKUP_STATUS } from "../../shared";
import { AuthService } from "../../services";

const RoutesSection = ({transRoutes, copyList, sectionName, drivers, vehicles, canAddNew, addText, copyText, redirect, routeType}) => {
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [routesOptions, setRouteOptions] = useState([]);
  const [newRoute, setNewRoute] = useState(undefined);
  const [newName, setNewName] = useState('');
  const dispatch = useDispatch();
  const { createRoute } = transRoutesSlice.actions;

  const openCopyModal = () => {
    setRouteOptions(copyList);
    setShowCopyModal(true);
  }

  const closeCopyModal = () => {
    setRouteOptions([]);
    setNewRoute(undefined);
    setNewName('');
    setShowCopyModal(false);
  }

  const saveRoute = () => {
    if (newRoute && newName) {
      const payload = Object.assign({}, copyList.find((item) => item.id === newRoute), { type: routeType, name: newName});
      delete payload.id;
      const customers = payload.route_customer_list.slice().sort((a, b) => b.customer_pickup_order - a.customer_pickup_order);
      let n = 0;
      for (let i=0; i< customers.length -1 ; i++) {
        if (i === 0) {
          customers[i] = Object.assign({}, customers[i], {customer_pickup_order_new: n});
        }
        if (customers[i+1].customer_pickup_order === customers[i].customer_pickup_order) {
          customers[i+1] = Object.assign({}, customers[i+1], {customer_pickup_order_new: n});
        } else {
          n++;
          customers[i+1] = Object.assign({}, customers[i+1], {customer_pickup_order_new: n});
        }
      }
      const customersPayload = customers.map(customer => {
        let newCustomer = {
          ...customer,
          customer_pickup_order: customer.customer_pickup_order_new
        }
        delete newCustomer.customer_pickup_order_new;
        return newCustomer;
      });
      const finalPayload = Object.assign({}, payload, {route_customer_list: customersPayload});
      dispatch(createRoute({data: finalPayload, fetchTomorrow: true}));
      closeCopyModal();
    }
  }

  const getSeniorsNumber = () => {
    const seniors = [];
    for (const transRoute of transRoutes) {
      for (const customer of transRoute.route_customer_list) {
        if (!seniors.includes(customer.customer_id) && customer.customer_pickup_status !== PICKUP_STATUS.SCHEDULE_ABSENT && customer.customer_route_status !== PERSONAL_ROUTE_STATUS.DISABLED) {
          seniors.push(customer.customer_id);
        }
      }
    }
    return seniors.length;
  }


  return (
    <>
      <div className={`text-primary ${canAddNew? 'mb-2' : ''}`}>
        <small className="me-4">
          {sectionName} { (sectionName.includes('Inbound') || sectionName.includes('Outbound')) && (` (${getSeniorsNumber()} seniors)`)}
        </small>
        { canAddNew && (
          <small className="me-4" onClick={() => { if (routeType) {redirect(routeType)} else {redirect()}}}>
            <a>{addText}</a>
          </small>)}
        {
          copyText && (
            <small onClick={() => {openCopyModal()} }>
              <a>{copyText}</a>
            </small>)}
      </div>
      {
        transRoutes && (<>
          <div className="list row">
            {
              transRoutes.map((route) => <div key={route.id} className="col-md-3 col-sm-6 col-xs-12"><RouteCard transRoute={route} drivers={drivers} vehicles={vehicles}/></div>)
            }
          </div>
        </>)
      }
      {
        AuthService.canViewDrivers() && !transRoutes && drivers && (<>
          <div className="list row">
            {
              drivers.map((driver) => <div key={driver.id} className="col-md-3 col-sm-6 col-xs-12"><RouteCard driver={driver}/></div>)
            }
          </div>
        </>)
      }
      {
        AuthService.canViewVechiles() && !transRoutes && vehicles && (<>
          <div className="list row">
            {
              vehicles.map((vehicle) => <div key={vehicle.id} className="col-md-3 col-sm-6 col-xs-12"><RouteCard vehicle={vehicle}/></div>)
            }
          </div>
        </>)
      }
      <Modal show={showCopyModal} onHide={() => closeCopyModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Copy Route</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="mb-4">Select an Existed Route: <select value={newRoute} onChange={(e) => {setNewRoute(e.currentTarget.value)}}>
              <option value=""></option>
              {routesOptions.map((item) => <option key={item.id} value={item.id}>{item.name}</option>)}
            </select></div>
            <div>Type in New Route Name: <input type="text" value={newName} onChange={(e) => {setNewName(e.currentTarget.value)}} /></div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => closeCopyModal()}>
            Close
          </Button>
          <Button variant="primary" onClick={() => saveRoute()}>
            Save Route
          </Button>
        </Modal.Footer>
      </Modal>
    </>
    
  );
};

export default RoutesSection;