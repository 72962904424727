import { createSlice, CaseReducer } from "@reduxjs/toolkit";

const initialState = {
  routes: [],
  tomorrowRoutes: [],
  historyRoutes: [],
  loading: false,
  error: null
};

const fetchAllHistoryRoutes = (state, action) => {
  state.loading = true;
};

const fetchAllHisotryRoutesSuccess = (state, action) => {
  state.error = null;
  state.loading = false;
  state.historyRoutes = action.payload;
}

const fetchAllHistoryRoutesFailure = (state, action) => {
  state.historyRoutes = [];
  state.error = action.payload;
};

const fetchAllRoutes = (state, action) => {
  state.loading = true;
};
const fetchAllRoutesSuccess = (state, action) => {
  state.error = null;
  state.loading = false;
  state.routes = action.payload;
};
const fetchAllRoutesFailure = (state, action) => {
  state.routes = [];
  state.error = action.payload;
};

const fetchAllTomorrowRoutes = (state, action) => {
  state.loading = true;
};
const fetchAllTomorrowRoutesSuccess = (state, action) => {
  state.error = null;
  state.loading = false;
  state.tomorrowRoutes = action.payload;
};
const fetchAllTomorrowRoutesFailure = (state, action) => {
  state.tomorrowRoutes = [];
  state.error = action.payload;
};

const updateRoute = (state, action) => {};
const updateRouteFailure = (state, action) => {
  state.error = action.payload;
};

const createRoute = (state, action) => {};
const createRouteFailure = (state, action) => {
  state.error = action.payload;
}

export const transRoutesSlice = createSlice({
  name: "transRoutes",
  initialState,
  reducers: {
    fetchAllRoutes,
    fetchAllRoutesSuccess,
    fetchAllRoutesFailure,
    updateRoute,
    updateRouteFailure,
    fetchAllTomorrowRoutes,
    fetchAllTomorrowRoutesSuccess,
    fetchAllTomorrowRoutesFailure,
    createRoute,
    createRouteFailure,
    fetchAllHisotryRoutesSuccess,
    fetchAllHistoryRoutes,
    fetchAllHistoryRoutesFailure
  }
});

