import http from "../http-common";
const getAll = (type) => {
  const params = {};
  if (type) {
    params.type = type;
  }
  return http.get("/resources", {params});
};

export const ResourceService = {
  getAll
};
