import { takeEvery, all, call, put } from 'redux-saga/effects';
import { transRoutesSlice } from './../store';
import { TransRoutesService } from './../services';

const {
  fetchAllRoutes,
  fetchAllRoutesSuccess,
  fetchAllRoutesFailure,
  updateRoute,
  updateRouteFailure,
  fetchAllTomorrowRoutes,
  fetchAllTomorrowRoutesSuccess,
  fetchAllTomorrowRoutesFailure,
  createRoute,
  createRouteFailure,
  fetchAllHisotryRoutesSuccess,
  fetchAllHistoryRoutes,
  fetchAllHistoryRoutesFailure
} = transRoutesSlice.actions;

function* fetchHistoryRoutesSaga(action) {
  try {
    const dateText = action.payload.dateText;
    const routes = (yield call(TransRoutesService.getAll, dateText)).data;
    yield put(fetchAllHisotryRoutesSuccess(routes));
  } catch(ex) {
    yield put(fetchAllHistoryRoutesFailure(ex));
  }
}

function* fetchRoutesSaga() {
  try {
    const date = new Date();
    const dateText = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
    const routes = (yield call(TransRoutesService.getAll, dateText)).data;
    yield put(fetchAllRoutesSuccess(routes));
  } catch(ex) {
    yield put(fetchAllRoutesFailure(ex));
  }
}

function* fetchAllTomorrowRoutesSaga() {
  try {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    const dateText = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
    const routes = (yield call(TransRoutesService.getAll, dateText)).data;
    yield put(fetchAllTomorrowRoutesSuccess(routes));
  } catch(ex) {
    yield put(fetchAllTomorrowRoutesFailure(ex));
  }
}

function* updateRouteSaga(action) {
  try {
    yield call(TransRoutesService.updateRoute, action.payload.id, action.payload.data);
    if (action.payload.dateText) {
      const routes = (yield call(TransRoutesService.getAll, action.payload.dateText)).data;
      yield put(fetchAllHisotryRoutesSuccess(routes));
    } else {
      const date = new Date();
      const dateText = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
      const routes = (yield call(TransRoutesService.getAll, dateText)).data;
      yield put(fetchAllRoutesSuccess(routes));
      date.setDate(date.getDate() + 1);
      const tmrDateText = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
      const routesTmr = (yield call(TransRoutesService.getAll, tmrDateText)).data;
      yield put(fetchAllTomorrowRoutesSuccess(routesTmr));
    }
  } catch(ex) {
    yield put(updateRouteFailure(ex));
  }
}

function* createRouteSaga(action) {
  try {
    yield call(TransRoutesService.createNewRoute, action.payload.data);
    if (action.payload.fetchToday) {
      const date = new Date();
      const dateText = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
      const routes = (yield call(TransRoutesService.getAll, dateText)).data;
      yield put(fetchAllRoutesSuccess(routes));
    }
    if (action.payload.fetchTomorrow) {
      const date = new Date();
      date.setDate(date.getDate() + 1);
      const dateText = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
      const routes = (yield call(TransRoutesService.getAll, dateText)).data;
      yield put(fetchAllTomorrowRoutesSuccess(routes));
    }
  } catch(ex) {
    yield put(createRouteFailure(ex));
  }
}

export function* transRoutesEffect() {
  yield all([
    yield takeEvery(fetchAllRoutes.type, fetchRoutesSaga),
    yield takeEvery(updateRoute.type, updateRouteSaga),
    yield takeEvery(fetchAllTomorrowRoutes.type, fetchAllTomorrowRoutesSaga),
    yield takeEvery(createRoute.type, createRouteSaga),
    yield takeEvery(fetchAllHistoryRoutes.type, fetchHistoryRoutesSaga)
  ])
};